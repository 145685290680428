.modal {
    position: absolute;
    z-index: 10000;
    box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
    top: 460px;
    left: 50%;
    width: 80%;
    height: 800px;
    padding: 20px;
    transform: translate(-50%,-50%);
    opacity: 0.97;
    background-image: url('../../img/s.jpeg');
    color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 300ms cubic-bezier(.03,.72,.44,.6) 100ms;
    padding: 10px;  

        &__close{
            position:  absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: linear-gradient(0deg, #61d507, #222222);
            cursor: pointer;
            width: 20px;
            height: 20px;
            z-index: 10000000000;
            
            &:hover{     
                transition: all 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;         
                width: 25px;
                height: 25px;        
            }
        }
    
}

@media(max-width:700px){
    .modal {
        width: 400px;
        height: 400px;
    }
}