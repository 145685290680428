.invest {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

    &__r1{
    width: 95%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 20px;
    margin-top: 70px;

    &__row1{
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 10%;
      &__col1{
        width: 60%;
        &__pergraf{
          border-radius: 20px;
          height:433px;
          width:650px;    
          box-shadow: 0px 0px 10px 2px rgba($color: yellow , $alpha: 1);  
        }
      }
      &__col2{
        width: 40%;
        &__narrow{
          border-radius: 20px;
          height: 508px;
          width: 405px;
          box-shadow: 0px 0px 10px 2px rgba($color: yellow , $alpha: 1);  
        }
      }
    }
    &__row2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__scrolltxt{
          animation: moveUpDown2 4s infinite alternate;
          font-size: 20px;
          color: white;
        }
        &__scroll{
          width: 100px;
          height: 100px;
          animation: moveUpDown 4s infinite alternate;
          
        }
    } 
  }  

  &__r2{
    width: 100%;
    height: 50px;
    background-image: url('../../../img/black_metal.jpg');
  }

  &__r3{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    &__col1{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: black;
      width: 100%;
      height: 100%;
      gap: 40px;
    }
    &__col2{
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: black;
      border-top: solid yellow 2px;
      width: 100%;
      height: 100%;
      gap: 40px;
    }

    &__titles{
      margin-top: 30px;
      font-size: 30px;
      color: yellow;
    }
    &__info{
      height: 100%;
      width: 80%;
      font-size: 20px;
      color: whitesmoke;
      line-height: 1.5;
    }
    &__info2{
      height: 100%;
      width: 80%;
      font-size: 20px;
      color: whitesmoke;
      line-height: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 10%;
    }
    &__info3{
      height: 100%;
      width: 80%;
      font-size: 20px;
      color: whitesmoke;
      line-height: 1.5;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 10%;
    }
    &__images{
      width: 100%;
      height: 100%;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__1{   
        width: 100%;
        height: 100%;
        border-radius: 100px;
        z-index: 1;
      }
    }
    &__images2{
      width: 200%;
      height: 200%;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__2{   
        width: 130%;
        height: 130%;
        border-radius: 100px;
        z-index: 1;
      }
    }
    &__btn{
      width: 30%;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      background: linear-gradient(20deg, #222222, #000000, #222222 );
      opacity: 0.9;
      color: rgb(255, 255, 255);
      font-size: 21px;
      border-radius: 10px;
      text-decoration: none;
      cursor: pointer;
      box-shadow: 0px 0px 105px 1px yellow;
      
      &:hover{
        background: linear-gradient(80deg, #000000, #222222 , #000000);
      }
    }
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(-80px) rotate(180deg); /* Start position */
  }
  100% {
    transform: translateY(-30px) rotate(180deg); /* End position */
  }
}
@keyframes moveUpDown2 {
  0% {
    transform: translateY(-50px); /* Start position */
  }
  100% {
    transform: translateY(100px); /* End position */
  }
}


@media (max-width:1100px){
  .invest {
    &__r1{

  
      &__row1{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10%;
        &__col1{
          width: 100%;
          &__pergraf{
            border-radius: 20px;
            height:417px;
            width:450px;    
            box-shadow: 0px 0px 10px 2px rgba($color: yellow , $alpha: 1);  
          }
        }
        &__col2{
          opacity: 0;
          &__narrow{
            border-radius: 20px;
            height: 0;
            width: 0;
            box-shadow: 0px 0px 10px 2px rgba($color: yellow , $alpha: 1);  
          }
        }
      }
      &__row2{
          &__scrolltxt{
            font-size: 20px;
            color: white;
          }
          &__scroll{
            margin-top: 10px;
            width: 70px;
            height: 70px;
          }
      } 
    }  
  
    &__r3{
      &__info{
        font-size: 0.7em;
      }
      &__info2{
        font-size: 0.7em;
      }
      &__info3{
        font-size: 0.7em;
      }
      &__images{
        width: 100%;
        height: 100%;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__1{   
          width: 160%;
          height: 160%;
          border-radius: 100px;
          z-index: 1;
        }
      }
      &__images2{
        width: 200%;
        height: 200%;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__2{   
          width: 160%;
          height: 160%;
          border-radius: 100px;
          z-index: 1;
        }
      }
    }  
 }
}