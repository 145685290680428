.home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__d1{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        &__main{
            display: flex;
            flex-direction: column;
            width: 80%;
            height: 700px;
            justify-content: center;
            align-items: center;
            flex: 1;
            &__logo{
                background-image: url('../../../img/metal_backg.jpg');
                width: 800px;
                height: 150px;
                border-radius: 30px;
                box-shadow: 0px 0px 0px 3px rgba($color: yellow , $alpha: 1);
                margin-right: 14%;
                display: flex;
                flex-direction: row;
                align-items: center;
                &__icon{
                    width: 200px;
                    height: 200px;
                    margin-left: 10%;
                    border-radius: 50%;
                }
                &__text{
                    display: felx;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10%;
                    &__medusa{
                        font-size: 44px;
                        text-decoration: bold;
                        color: aliceblue;
                    }
                    &__info{
                        top: 40px;
                        left: 40px;
                        font-size: 30px;
                        color: aliceblue;
                    }
                }
            }
        }
        &__img{
            flex:1;
            width: 20%;
            height: 700px;
            background-image: url('../../../img/medusa.png');
            background-size: cover; 
            background-position: center; 
            background-position: calc(center - 400px);
            animation: moveBackground 8s infinite alternate;
        }

    }

    &__d2{
        box-shadow: 0px -2px 0px 1px rgba($color: yellow , $alpha: 1);
        margin-top: 100px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &__title{
            font-size: 40px;
            color: white;
            margin-bottom: 40px;
            margin-top: 40px;
        }
        &__content{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            &__r1{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;

                &__c1{
                    background-image: url('../../../img/black_metal.jpg');
                    width: 500px;
                    height: 300px;
                    border-radius: 20px 20px 20px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    &__title{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(50px);
                        font-size: 30px;
                        color: yellow;
                    }
                    &__p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(100px);
                        width: 80%;
                        font-size: 20px;
                        color: seashell;
                    }
                }
                &__c2{
                    background-image: url('../../../img/black_metal.jpg');
                    width: 500px;
                    height: 300px;
                    border-radius: 20px 20px 20px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                   
                    
                    &__title{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(50px);
                        font-size: 30px;
                        color: yellow;
                    }
                    &__p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(100px);
                        width: 80%;
                        font-size: 20px;
                        color: seashell;
                    }
                }
            }
            &__r2{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                &__c1{
                    background-image: url('../../../img/black_metal.jpg');
                    width: 500px;
                    height: 300px;
                    border-radius: 20px 20px 20px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    
                    &__title{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(50px);
                        font-size: 30px;
                        color: yellow;
                    }
                    &__p{
                        width: 80%;
                        transform: translateY(80px);
                        font-size: 20px;
                        color: seashell;
                    }
                }
                &__c2{
                    background-image: url('../../../img/black_metal.jpg');
                    width: 500px;
                    height: 300px;
                    border-radius: 20px 20px 20px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    
                    &__title{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(50px);
                        font-size: 30px;
                        color: yellow;
                    }
                    &__p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: translateY(80px);
                        width: 80%;
                        font-size: 20px;
                        color: seashell;
                    }
                }
            }
        }
    }
    &__d3{
        height: 1000px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 70px;
        &__border{
            background-image: url('../../../img/metal_backg.jpg');
            width: 100%;
            height: 66px;
        }
        &__title{
            font-size: 40px;
            color: rgb(255, 255, 255);
            margin-top: 40px;
            margin-bottom: 40px;
        }
        &__content{
            font-size: 30px;
            color: rgb(220, 166, 166);
            display: flex;
            flex-direction: row;
            margin-bottom: 70px;
            &__p{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                color: white;
                &__p{
                    width: 70%;
                }
            }
            &__results{
                display: flex;
                flex-direction: row;
                gap: 20px;
                
                &__result1{
                    width: 281px;
                    height: 581px;
                    border-radius: 30px;
                    z-index: 1;
                }
                &__result2{
                    display: flex;
                    flex-direction: column;
                    &__text{
                        display: flex;
                        justify-content: center;
                        margin-left: 40%;
                        transform: translateX(-70px) translateY(10px) rotate(10deg);
                        color: yellow;
                    }
                    &__img{
                        width: 281px;
                        height: 581px;
                        border-radius: 30px;
                        z-index: 0;
                        transform: translateX(-70px) translateY(10px) rotate(10deg);
                    }
                }
            }
        }        
    }

    &__d4{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 50px;
        margin-left: 3%;
        margin-bottom: 10%;
        margin-top: 5%;
        &__diagram{
            width: 894px;
            height: 467px;
            border-radius: 20px;
            box-shadow: 0px 0px 200px 0px rgba($color: yellow , $alpha: 0.4);
        }
        &__context{
            display: flex;
            justify-content: center;
            font-size: 40px;
            color: white;
        }
    }

    &__sponsors{
        margin-bottom:100px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 100%;
        box-shadow: 0px -3px 0px 0px rgba($color: yellow , $alpha: 1);
        &__kworks{
            margin-top: 90px;
            height: 240px;
            width: 427px;
        }
    }
}

@keyframes moveBackground {
    0% {
      background-position: right 0px;
    }
    100% {
      background-position:  right 100px ;
    }
  }  

@media (max-width:1100px){
    .home {
        &__d1{
            &__main{
                &__logo{
                    width: 700px;
                    height: 130px;
                    box-shadow: 0px 0px 0px 2px rgba($color: yellow , $alpha: 1);
                    margin-right: 26%;
                    &__icon{
                        width: 110px;
                        height: 110px;
                    }
                    &__text{
                        margin-left: 13%;
                        &__medusa{
                            font-size: 34px;
                        }
                        &__info{
                            font-size: 27px;
                        }
                    }
                }
            }
            &__img{
                height: 620px;
            }
    
        }

    &__d2{
        box-shadow: 0px -1px 0px 1px rgba($color: yellow , $alpha: 1);        
        &__title{
            font-size: 30px;
        }
        &__content{
            &__r1{
                &__c1{
                    width: 400px;
                    height: 300px;  
                    &__title{
                        transform: translateY(50px);
                        font-size: 30px;
                    }
                    &__p{
                        transform: translateY(100px);
                        width: 80%;
                        font-size: 17px;
                    }
                }
                &__c2{
                    width: 400px;
                    height: 300px;
                    &__title{
                        transform: translateY(50px);
                        font-size: 30px;
                    }
                    &__p{
                        transform: translateY(100px);
                        width: 80%;
                        font-size: 17px;
                    }
                }
            }
            &__r2{
                &__c1{
                    width: 400px;
                    height: 300px;
                    &__title{
                        transform: translateY(50px);
                        font-size: 30px;
                    }
                    &__p{
                        transform: translateY(100px);
                        width: 80%;
                        font-size: 17px;
                    }
                }
                &__c2{
                    width: 400px;
                    height: 300px;
                    &__title{
                        transform: translateY(50px);
                        font-size: 30px;
                    }
                    &__p{
                        transform: translateY(100px);
                        width: 80%;
                        font-size: 17px;
                    }
                }
            }
        }
    }

    &__d3{
        &__title{
            font-size: 34px;
        }
        &__content{
            font-size: 30px;
            &__results{                
                &__result1{
                    width: 225px;
                    height: 465px;
                }
                &__result2{
                    &__img{
                        width: 225px;
                        height: 465px;
                    }
                }
            }
        }        
    }

    &__d4{
        gap: 50px;
        margin-left: 3%;
        margin-bottom: 40%;  
        justify-content: center;
        &__diagram{
            width: 596px;
            height: 311px;
            box-shadow: 0px 0px 100px 0px rgba($color: yellow , $alpha: 0.4);
        }
        &__context{

            font-size: 20px;
          
        }
    }
    
    &__sponsors{
        height: 160px;
        box-shadow: 0px -2px 0px 0px rgba($color: yellow , $alpha: 1);
    }
}

@keyframes moveBackground {
    0% {
      background-position: right 10px;
    }
    100% {
      background-position:  right 140px ;
    }
  }  

}

@media (max-width:950px){
    .home {
        &__d1{
            &__img{
                height: 520px;
            }
        }
    }    
    @keyframes moveBackground {
        0% {
          background-position: right 10px;
        }
        100% {
          background-position:  right 120px ;
        }
      }
}  

@media (max-width:920px){
        .home {
            &__d1{
                margin-top: 50px;
                &__main{
                    height: 400px;
                    &__logo{
                        width: 300px;
                        height: 80px;
                        box-shadow: 0px 0px 0px 2px rgba($color: yellow , $alpha: 1);
                        margin-right: 26%;
                        &__icon{
                            width: 66px;
                            height: 66px;
                        }
                        &__text{
                            margin-left: 8%;
                            &__medusa{
                                font-size: 21px;
                            }
                            &__info{
                                font-size: 15px;
                            }
                        }
                    }
                }
                &__img{
                    height: 320px;
                }
            }
        

        &__d2{
            margin-top: -10px;
            box-shadow: -1px -1px 0px 1px rgba($color: yellow , $alpha: 1);        
            &__title{
                font-size: 20px;
            }
            &__content{
                gap: 10px;
                &__r1{
                    gap: 10px;
                    &__c1{
                        width: 200px;
                        height: 180px;  
                        &__title{
                            transform: translateY(20px);
                            font-size: 20px;
                        }
                        &__p{
                            transform: translateY(30px);
                            width: 80%;
                            font-size: 10px;
                        }
                    }
                    &__c2{
                        width: 200px;
                        height: 180px;  
                        &__title{
                            transform: translateY(20px);
                            font-size: 20px;
                        }
                        &__p{
                            transform: translateY(30px);
                            width: 80%;
                            font-size: 10px;
                        }
                    }
                }
                &__r2{
                    gap: 10px;
                    &__c1{
                        width: 200px;
                        height: 180px;  
                        &__title{
                            transform: translateY(20px);
                            font-size: 20px;
                        }
                        &__p{
                            transform: translateY(30px);
                            width: 80%;
                            font-size: 10px;
                        }
                    }
                    &__c2{
                        width: 200px;
                        height: 180px;  
                        &__title{
                            transform: translateY(20px);
                            font-size: 20px;
                        }
                        &__p{
                            transform: translateY(30px);
                            width: 80%;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    
        &__d3{
            margin-top: -200px;
            &__border{
                height: 40px;
            }
            &__title{
                font-size: 20px;
            }
            &__content{
                font-size: 17px;

                &__p{
                    font-size: 13px;
                }
                &__results{            
                    width: 50%;    
                    &__result1{
                        width: 113px;
                        height: 233px;
                        border-radius: 10px;
                    }
                    &__result2{
                        &__text{
                            font-size: 13px;
                            margin-left: 60px;
                        }
                        &__img{
                            width: 113px;
                            height: 233px;
                            border-radius: 10px;
                        }
                    }
                }
            }        
        }
        
        &__d4{
            gap: 50px;
            margin-left: 3%;
            margin-bottom: 70%;  
            justify-content: center;
            &__diagram{
                width: 447px;
                height: 233px;
                box-shadow: 0px 0px 50px 0px rgba($color: yellow , $alpha: 0.4);
            }
            &__context{
    
                font-size: 20px;
              
            }
        }

        &__sponsors{
            margin-top: -200px;
            height: 30px;
            box-shadow: 0px -2px 0px 0px rgba($color: yellow , $alpha: 1);

            &__kworks{
                height: 120px;
                width: 213px;
            }
        }
    } 
    @keyframes moveBackground {
        0% {
          background-position: right 10px;
        }
        100% {
          background-position:  right 70px ;
        }
      }  
    
}  
