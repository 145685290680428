.dev {
    position: relative; 
    width: 100%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
    background-image: url('../../../img/dev.webp');

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity here */
        z-index: -1; 
    }

    &__coming {
        color: white;
        font-size: 40px;
        position: relative; /* Ensure stacking context */
        z-index: 1; /* Ensure the text is above the pseudo-element */
    }
}