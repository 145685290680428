.team {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__l1{
        width: 70%;
        height: 100px;
        color: white;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    &__l2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-bottom: 10% ;
        
        &__plantitle{
           font-size: 34px;
           color: white;
           margin-bottom: 5%;
        }
        &__team{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10%;
            background-color: rgb(0, 0, 0);
            height: 100%;
            width: 100%;
            color: white;

            &__p2{
                background-image: url('../../../img/metal_backg.jpg');
                width: 28%;
                height: 478px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                &__prof{
                    width: 210px;
                    height: 200px;
                    border-radius: 50%;
                    margin-top: 5%;
                }
                &__name{
                    font-size: 30px;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5%;
                }
                &__b{
                    margin-top: 3%;
                }
                
                &__contact{
                    margin-top: 10%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &__linked{
                        top: 600px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-right: 30px;
                        color: white;
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                        &__img{
                            width: 44px;
                            height: 44px;
                        }
                    }
                    &__whatsapp{
                        top: 600px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-right: 30px;
                        color: white;
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                        &__img{
                            width: 44px;
                            height: 44px;
                        }
                    }
                }

                &:hover{
                    box-shadow: 0px 0px 20px 1px rgba($color: gold , $alpha: 0.9);
                }
            }
        }
    }
}

@media (max-width:1200px){
    .team {
        &__l2{
            width: 130%;
            margin-bottom: 10%;
    
            }
        }
    }    
@media (max-width:800px){
    .team {
        &__l2{
            width: 150%;
            margin-bottom: 10%;
    
            }
        }
    }    
@media (max-width:700px){
    .team {
        &__l2{
            width: 180%;
            margin-bottom: 10%;
            }
        }
    }      
@media (max-width:600px){
    .team {
        &__l2{
            width: 190%;
            margin-bottom: 10%;
            }
        }
    }     
@media (max-width:550px){
    .team {
        &__l2{
            width: 200%;
            margin-bottom: 10%;
            }
        }
    }                  
@media (max-width:500px){
    .team {
        &__l2{
            width: 260%;
              margin-bottom: 10%;
            }
        }
    }             