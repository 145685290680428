.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 0px;
    background-color: rgb(0, 0, 0);

    &__menu{
        position: fixed;
        background-color: #000000;
        padding: 1px 2px;
        z-index: 1000000;
        margin-top: 0.33%;
        height: 33px;
        width: 40%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        border-radius: 10px 10px 10px 10px;

        &__api{
            width: 33%;
            height: 90%;
            background: linear-gradient(90deg, #5d5d5d, #222222);
            opacity: 0.8;
            font-size: 21px;
            border: none;
            border-radius: 10px 0px 0px 10px;
            cursor: pointer;
            box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 253, 245) , $alpha: 0.4);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: gray;
            
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(211, 196, 196),3%);
            }
        }

        &__invest{
            width: 33%;
            height: 90%;
            background: linear-gradient(90deg, #232323, rgb(47, 47, 47));
            opacity: 0.8;
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 0px 0px;
            cursor: pointer;
            box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: gray;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(211, 196, 196),3%);
            }
        }
        &__dev{
            width: 33%;
            height: 90%;
            background: linear-gradient(270deg, #5d5d5d, #222222);
            opacity: 0.8;
            font-size: 21px;
            border: none;
            border-radius: 0px 10px 10px 0px;
            cursor: pointer;
            box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: gray;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(211, 196, 196),3%);
            }
        }
    }

    &__home{
        position: fixed;
        background: linear-gradient(270deg, #5d5d5d, #222222);
        opacity: 0.8;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        left: 10px;
        height: 53px;
        width: 53px;
        border-radius: 20px 20px 20px 20px;
        cursor: pointer;
        font-size: 18px;
        text-decoration: none;
        color: gray;
        box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
    }
    &__profile{
        position: fixed;
        background: linear-gradient(270deg, #5d5d5d, #222222);
        opacity: 1;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 10px;
        height: 53px;
        width: 53px;
        border-radius: 20px 20px 20px 20px;
        cursor: pointer;
        font-size: 18px;
        text-decoration: none;
        color: gray;
        box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
    }
    &__register{
        position: fixed;
        background: linear-gradient(270deg, #5d5d5d, #222222);
        opacity: 0.8;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 200px;
        height: 53px;
        width: 53px;
        border-radius: 20px 20px 20px 20px;
        cursor: pointer;
        font-size: 18px;
        text-decoration: none;
        color: gray;
        box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
    }
    &__login{
        position: fixed;
        background: linear-gradient(270deg, #5d5d5d, #222222);
        opacity: 0.8;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 10px;
        height: 53px;
        width: 53px;
        border-radius: 20px 20px 20px 20px;
        cursor: pointer;
        font-size: 18px;
        text-decoration: none;
        color: gray;
        box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
    }
}

.modalp{  
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__profile{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        &__info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10%;
            width: 30%;
            border-right: solid 2px white;
            &__picture{
                background-image: url('../../img/prof.jpg');
                background-size: 100%;
                width: 80%;
                height: 40%;
                border-radius: 40%;
                
            }
            &__name{
                font-size: 30px;
                
            }
            &__type{
                font-size: 20px;
                
            }

        }
        &__investment{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            height: 100%;
            

            &__numbers{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 33%;
                margin-bottom: 5%;
                &__balance{
                    font-size: 30px;
                }
                &__deposite{
                    font-size: 20px;
                }
            }
            &__graf{
                background-image: url('../../img/graph.jpeg');
                background-size: 100%;
                width: 90%;
                height: 60%; 
                border-radius: 10%;
                margin-bottom: 5%;
            }
            &__btns{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 100%;
                margin-top: 30px;
                &__deposite{
                    width: 20%;
                    height: 30px;
                    background: linear-gradient(45deg, green, gold);
                    border-radius: 10px;
                    border: none;
                    font-size: 21px;
                    cursor: pointer;
                    box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
                    transition: width 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                    color: rgb(0, 0, 0);
                    &:hover{
                        transition: width 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                        width: 21%;
                        height: 32px;
                        background-color: darken( rgb(211, 196, 196),3%);
                    }
                }
                &__withdraw{
                    width: 20%;
                    height: 30px;
                    background: linear-gradient(45deg, red, #222222);
                    border: none;
                    border-radius: 10px;
                    font-size: 21px;
                    cursor: pointer;
                    box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
                    transition: width 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                    color: rgb(0, 0, 0);
                    &:hover{
                        transition: width 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                        width: 21%;
                        height: 32px;
                        background-color: darken( rgb(211, 196, 196),3%);
                    }
                }
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
}

@media (max-width:700px){
    .header {
        &__menu{
            height: 29px;
            width: 50%;
            &__api{
                width: 33%;
                height: 90%;
                opacity: 0.8;
                font-size: 14px;
                box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 253, 245) , $alpha: 0.4);
                transition: height 300ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                &:hover{
                    transition: height 300ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                    height: 110%;
                }
            }
    
            &__invest{
                width: 33%;
                height: 90%;
                opacity: 0.8;
                font-size: 14px;
                box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 253, 245) , $alpha: 0.4);
                transition: height 300ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                &:hover{
                    transition: height 300ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                    height: 110%;
                }
            }
            &__dev{
                width: 33%;
                height: 90%;
                opacity: 0.8;
                font-size: 14px;
                box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 253, 245) , $alpha: 0.4);
                transition: height 300ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                &:hover{
                    transition: height 300ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                    height: 110%;
                }
            }
            &__home{
                position: fixed;
                background: linear-gradient(270deg, #5d5d5d, #222222);
                opacity: 0.8;
                z-index: 1000;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 10px;
                left: 10px;
                height: 33px;
                width: 33px;
                border-radius: 20px 20px 20px 20px;
                cursor: pointer;
                font-size: 18px;
                text-decoration: none;
                color: gray;
                box-shadow: 0px 0px 10px 3px rgba($color: rgb(255, 255, 255) , $alpha: 0.4);
            }    
        }
    }
}