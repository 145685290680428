
.footer {
    display: flex;
    flex-direction: row;
    background-color: rgb(0, 0, 0);
    height: 66px;
    align-items: center;
    border-top: solid 3px yellow;
    color: white;
    &__copyright-p{
        &__span{
            color: red;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    &__links{
        display: flex;
        margin-left: auto;
        margin-right: 50px;

    }

    &__link{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 30px;
        color: white;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        &__img{
            width: 44px;
            height: 44px;
        }
    }
}

@media (max-width:700px){
    .footer {
        border-top: solid 2px yellow;
        &__copyright-p{
            margin-left: 20px;
            font-size: 10px;
            margin-right: 40px;
            &__span{
                font-size: 10px;
            }
        }
        &__link{
            font-size: 10px;

            &__img{
                width: 23px;
                height: 23px;
            }
        }
    }
}