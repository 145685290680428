.login {
    width: 100%;
    height: 90vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__box{
        width: 600px;
        height: 300px;
        font-size: 40px;
        z-index: 1; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        box-shadow: 0px 0px 0px 3px rgba($color: yellow , $alpha: 1);
        border-radius: 10px;


        &__title{
            color: white;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 10px;
        }
        &__email{
            background-color: black;
            color: white;
            width: 250px;
            height: 30px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 1px rgba($color: yellow , $alpha: 0.7);  
            &:hover{
                box-shadow: 0px 0px 10px 3px rgba($color: yellow , $alpha: 1);
            }
        }
        &__password{
            background-color: black;
            color: white;
            width: 250px;
            height: 30px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 1px rgba($color: yellow , $alpha: 0.7);  
            &:hover{
                box-shadow: 0px 0px 10px 3px rgba($color: yellow , $alpha: 1);
            }
        }
        &__button{
            width: 100%;
            height:50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &__login{
                width: 130px;
                height: 30px;
                border-radius: 10px;
                background-color: black;
                color: white;
                cursor: pointer;
                &:hover{
                    box-shadow: 0px 0px 1px 1px rgba($color: yellow , $alpha: 1);
                }

                &__link{
                    color: white;
                    text-decoration: none;
                    width: 100%;
                    height: 100%;
                }
            }
            &__register{
                width: 130px;
                height: 30px;
                border-radius: 10px;
                background-color: black;
                color: white;
                cursor: pointer;
                &:hover{
                    box-shadow: 0px 0px 1px 1px rgba($color: yellow , $alpha: 1);
                }
            }
        }
    }

}