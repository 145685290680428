.app {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;

  &__header {
    margin-bottom: auto;
    flex: 1;
  }
  &__body{
    display: flex;
    flex: 1;
  }
  &__footer{
    margin-top: auto;
    flex: 1;
  }

}
